import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <ul className="icons">                    
                    <li><a href="https://twitter.com/_johangant" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                    <li><a href="https://github.com/johangant" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                    <li><a href="https://www.linkedin.com/in/johangant/" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>&copy; Johan Gant</li>
                    <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                    <li>Built with <a href="https://www.gatsbyjs.org/" title="GatsbyJS">Gatsby</a> and hosted on <a href="https://www.netlify.com/" title="Netlify hosting">Netlify</a></li>
                </ul>
            </section>
        )
    }
}

export default Footer
