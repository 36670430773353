import React from 'react'

import profilePic from '../assets/images/me.png'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <div className="inner">
                    <img src={profilePic} alt="Hello, it's a picture of me" title="Hello, it's a picture of me" class="profile" />
                    <h1>Web developer and technology consultant</h1>
                    <p>Highly-skilled developer and consultant with a specialty in large scale, distributed and open-source content driven web projects.</p>                    
                    <ul className="actions">
                        <li><a href="#about" className="button scrolly">About me</a></li>                                                
                        <li><a href="#work" className="button scrolly">What I do</a></li>
                        <li><a href="#clients" className="button scrolly">Clients</a></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Header
